import { graphql } from "gatsby"
import React from "react"
import Page from "../components/page"
import RequestPricingTemplate from "../components/request-pricing-template"
import LogoFullImg from "../img/logo-loadmate-full.svg"
import LogoSmallImg from "../img/logo-loadmate-small.svg"

const RequestPricingLoadmatePage = ({
  data: { footerData, requestPricingLoadmatePageData },
}) => {
  return (
    <Page
      logo={{
        full: LogoFullImg,
        small: LogoSmallImg,
        alt: "Ship.Cars LoadMate",
        link: "/loadmate-solution",
      }}
      headerData={{ frontmatter: {} }}
      footerData={footerData}
    >
      <RequestPricingTemplate
        data={requestPricingLoadmatePageData.frontmatter}
      />
    </Page>
  )
}

export default RequestPricingLoadmatePage

export const pageQuery = graphql`
  query RequestPricingLoadmateQuery {
    ...PageFragment
    requestPricingLoadmatePageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/request-pricing-loadmate.md/" }
    ) {
      frontmatter {
        title
      }
    }
  }
`
